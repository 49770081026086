import React, { useEffect, useState } from "react";
import { Form, Input, Button, Row, Col } from "antd";
import PhoneInput from "react-phone-input-2";
import {
  DataGetAction,
  DataRequestAction,
} from "../../../../context/CommonHttp";
import { useApi } from "../../../../Context";
const SignupForm = ({ setIndex }) => {
  const [phoneNumber, setPhoneNumber] = useState();
  const { setConfig, config } = useApi();
  const onFinish = (values) => {
    values['phoneNumber'] = `+${values.phoneNumber}`
    if (
      config &&
      config.selectedPackege &&
      config.selectedPackege.packageDuration === "free"
    ) {
      DataRequestAction(
        "post",
        "signUp",
        setConfig,
        values,
        "signUpRecord",
        "SignupSpin",
        "signupUserSuccess",
        "signupUserError"
      );
    } else {
      DataGetAction(
        "emailCheck",
        setConfig,
        { email: values.email },
        "",
        "SignupSpin",
        "SignupSuccess",
        "errorSuccess"
      );
    }
    setConfig("signUpData", values);
  };

  useEffect(() => {
    if (config && config.SignupSuccess) {
      setIndex(2);
      setConfig("SignupSuccess", false);
    }
  }, [config && config.SignupSuccess]);
  return (
    <Form onFinish={onFinish} layout="vertical" className="auth-form">
      <Form.Item
        label="Name"
        name="fullName"
        rules={[{ required: true, message: "Name is required!" }]}
      >
        <Input placeholder="Enter your name" />
      </Form.Item>

      <Form.Item
        label="Email"
        name="email"
        rules={[
          { required: true, message: "Email is required!" },
          { type: "email", message: "Enter valid email!" },
        ]}
      >
        <Input placeholder="Enter your email" />
      </Form.Item>

      <Form.Item
        label="Store Name"
        name="storeName"
        rules={[{ required: true, message: "Store name is required!" }]}
      >
        <Input placeholder="Enter your store name" />
      </Form.Item>
      <Form.Item
        label="Store Address"
        name="storeAddress"
        rules={[{ required: true, message: "Store address is required!" }]}
      >
        <Input placeholder="Enter your store address" />
      </Form.Item>
      <Row>
        <Col xl={12} lg={12} md={12} xs={24} sm={24} className="pe-md-2 pe-0">
          <Form.Item
            label="Phone Number"
            name="phoneNumber"
            rules={[{ required: true, message: "Phone number is required!" }]}
          >
            <PhoneInput
              inputStyle={{
                width: "100%",
              }}
              country={"us"}
              value={phoneNumber}
              onChange={(phone) => setPhoneNumber({ phone })}
            />
          </Form.Item>
        </Col>
        <Col xl={12} lg={12} md={12} xs={24} sm={24}>
          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: "Password is required!" }]}
          >
            <Input.Password placeholder="Enter your password" />
          </Form.Item>
        </Col>
      </Row>

      <Button
        disabled={config && config.SignupSpin}
        loading={config && config.SignupSpin}
        htmlType="submit"
        type="primary"
        className="w-100 extra-bold-font h-40px mt-3"
      >
        Sign up
      </Button>
    </Form>
  );
};

export default SignupForm;
