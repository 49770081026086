import * as React from "react";
import { Table, InputNumber, Tooltip } from "antd";
import { useEffect } from "react";
import { AiFillDelete } from "react-icons/ai";
const EditableTable = ({ setTableData, tableData }) => {
  useEffect(() => {
    const newData = [...tableData];
    for (let index = 0; index < tableData.length; index++) {
      console.log('');
    }
    setTableData(newData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onInputChange = (key, index, e) => {
    if (e !== 0) {
      let newData = [...tableData];
      const existingIndex = tableData.findIndex(
        (d) => d._id === newData[index]._id
      );
      let updateData = tableData[existingIndex];

      if (e <= parseFloat(updateData["quantity"])) {
        newData[index][key] = Number(e);
        setTableData(newData);
      }
    }
  };
  const removeProduct = (index) => {
    setTableData([
      ...tableData.slice(0, index),
      ...tableData.slice(index + 1, tableData.length),
    ]);
  };
  const columns = [
    {
      render: (record) => (
        <span className="text-capitalize">
          {record.bookName && record.bookName.length > 18 ? (
            <Tooltip placement="right" title={record.bookName}>
              {record.bookName &&
                record.bookName.length > 18 &&
                record.bookName.slice(0, 18) + "..."}
            </Tooltip>
          ) : (
            record.bookName || "---"
          )}
        </span>
      ),
      title: "Book Name",
    },
    {
      dataIndex: "addedQuantity",
      title: "Quantity",
      render: (text, record, index) => (
        <InputNumber
          value={text}
          onChange={(e) => {
            onInputChange("addedQuantity", index, e);
          }}
        />
      ),
    },
    {
      dataIndex: "price",
      title: "Price",
    },
    {
      title: "",
      render: (text, record, index) => (
        <span onClick={() => removeProduct(index)}>
          <AiFillDelete color="red" size="24px" />
        </span>
      ),
      width: "80px",
    },
  ];

  return (
    <Table
      rowKey="id"
      columns={columns}
      dataSource={tableData}
      pagination={false}
    />
  );
};

export default EditableTable;
