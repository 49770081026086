import React, { useEffect, useState } from "react";
import { Modal, Form, Input } from "antd";
import { useApi } from "../../../Context";
import { IoMdCloseCircle } from "react-icons/io";
import { DataRequestAction } from "../../../context/CommonHttp";
import PhoneInput from "react-phone-input-2";
const EditSetting = ({ initialValues, editButton, width }) => {
  const [form] = Form.useForm();
  const { config, setConfig } = useApi();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState();

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = (values) => {
    if (values && values.phoneNumber && !values.phoneNumber.includes("+")) {
      values["phoneNumber"] = "+" + values.phoneNumber;
    }
    DataRequestAction(
      "put",
      "updateProfile",
      setConfig,
      values,
      "profileUpdateData",
      "UpdateProfileSpin",
      "UpdateProfileSuccess",
      "UpdateProfileError"
    );
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    form.resetFields();
  };
  if (config && config.UpdateProfileError) {
    setConfig("UpdateProfileError", false);
  }
  useEffect(() => {
    if (config && config.UpdateProfileSuccess) {
      setIsModalOpen(false);
      let obj = JSON.parse(localStorage.getItem("userInfo"));
      obj["fullName"] = config.profileUpdateData.fullName;
      obj["phoneNumber"] = config.profileUpdateData.phoneNumber;
      localStorage.setItem("userInfo", JSON.stringify(obj));
      setConfig("user", obj);
      setConfig("UpdateProfileSuccess", false);
    }
  }, [config && config.UpdateProfileSuccess]);
  return (
    <>
      <span onClick={() => showModal()}>{editButton}</span>
      <Modal
        className="form-modal"
        title="Update Profile"
        open={isModalOpen}
        width={width}
        okText="Save"
        cancelText="Cancel"
        confirmLoading={config && config.UpdateProfileSpin}
        closeIcon={<IoMdCloseCircle size="20" />}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              handleOk(values);
            })
            .catch((info) => {});
        }}
        onCancel={handleCancel}
      >
        <hr />
        <div className="px-3">
          <Form
            layout="vertical"
            initialValues={initialValues}
            name="Form"
            form={form}
          >
            <Form.Item
              name="fullName"
              label="Full Name"
              rules={[{ required: true, message: "Full name is required" }]}
            >
              <Input className="text-capitalize" />
            </Form.Item>
            <Form.Item
              name="phoneNumber"
              label="Phone Number"
              rules={[{ required: true, message: "Full name is required" }]}
            >
              <PhoneInput
                inputStyle={{
                  width: "100%",
                }}
                country={"us"}
                value={phoneNumber}
                onChange={(phone) => setPhoneNumber({ phone })}
              />
            </Form.Item>
          </Form>
        </div>
        <hr />
      </Modal>
    </>
  );
};
export default EditSetting;
