import { Button, Col, Empty, Modal, Row } from "antd";
import React, { useRef, useState } from "react";
import { IoMdCloseCircle } from "react-icons/io";
import ReactToPrint from "react-to-print";
const ViewInvoice = ({ record, grandTotal }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const componentRef = useRef();
  const renderTablesRow = () => {
    if (record && record.length > 0) {
      return record.map((item) => {
        return (
          <Row className="ps-3 pe-lg-0 pe-3 mb-2">
            <Col xl={6} lg={6} md={6} sm={6} xs={6}>
              <span className="regular-font font-16 text-capitalize">
                {item.bookName}
              </span>
            </Col>
            <Col xl={6} lg={6} md={6} sm={6} xs={6}>
              <span className="regular-font font-16">{item.addedQuantity}</span>
            </Col>
            <Col xl={6} lg={6} md={6} sm={6} xs={6}>
              <span className="regular-font font-16">${item.price}</span>
            </Col>
            <Col xl={6} lg={6} md={6} sm={6} xs={6}>
              <span className="regular-font font-16">${item.totalPrice}</span>
            </Col>
          </Row>
        );
      });
    } else {
      return (
        <div className="d-flex align-items-center justify-content-center">
          <Empty />
        </div>
      );
    }
  };

  return (
    <>
      <span
        onClick={() => setIsModalOpen(true)}
        className="primary-color pointer"
      >
        View
      </span>
      <Modal
        title={"Webyo Book Store"}
        className="invoice-modal"
        open={isModalOpen}
        closeIcon={
          <IoMdCloseCircle onClick={() => setIsModalOpen(false)} size="20" />
        }
        width={"600px"}
        footer={null}
      >
        <div style={{ position: "relative" }}>
          <div
            className="w-100 d-flex justify-content-end pe-4"
            style={{ position: "absolute", top: "14px" }}
          >
            <ReactToPrint
              trigger={(e) => (
                <Button
                  type="primary"
                  className="bold-font"
                  style={{ width: "100px" }}
                >
                  Print
                </Button>
              )}
              content={() => componentRef.current}
            />
          </div>
          <div ref={componentRef}>
            <div className="px-3 pt-3 d-flex align-items-center justify-content-between">
              <span className="font-18 regular-font">Sale Receipt</span>
            </div>
            <hr />

            <Row className="ps-3 pe-lg-0 pe-3 mb-2">
              <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                <span className="bold-font font-16">Book Name</span>
              </Col>
              <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                <span className="bold-font font-16">Quantity</span>
              </Col>
              <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                <span className="bold-font font-16">Book Price</span>
              </Col>
              <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                <span className="bold-font font-16">Total</span>
              </Col>
            </Row>
            {renderTablesRow()}
            <hr />
            <div className="d-flex justify-content-end pe-3">
              <span className="font-22 bold-font">
                Grand Total:{" "}
                <span className="font-18 bold-font">${grandTotal}</span>
              </span>
            </div>
            <hr />
            <div className="px-3 pb-3">
              <span>Note:</span>
              <div
                className="py-2 px-3 mt-1"
                style={{ background: "#FFFFFF", border: "1px solid #22181C" }}
              >
                This is a computer generated invoice and does not need any
                signature
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ViewInvoice;
