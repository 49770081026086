import React, { useEffect } from "react";
import { Table } from "antd";
import { useApi } from "../../Context";
import { DataGetAction } from "../../context/CommonHttp";

const TableList = ({ apiName, recordName, columns }) => {
  const { setConfig, config } = useApi();
  const callApi = () => {
    DataGetAction(
      apiName,
      setConfig,
      { query: "all" },
      recordName,
      "FetchSpinner"
    );
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(callApi, []);

  return (
    <Table
      className="table-responsive"
      columns={columns}
      loading={config && config.FetchSpinner}
      dataSource={
        config && config.FetchSpinner
          ? []
          : (config && config[recordName] && config[recordName].tableData) || []
      }
    />
  );
};
export default TableList;
