import React, { useEffect, useState } from "react";
import AddFormModal from "../../../components/Modal/AddFormModal";
import { Button, Form, InputNumber } from "antd";
import BookStoreForm from "./BookStoreForm";
import { useApi } from "../../../Context";
import { DataGetAction } from "../../../context/CommonHttp";
import moment from "moment";

const AddBookStore = () => {
  const { config, setConfig } = useApi();
  const [form] = Form.useForm();
  const [QRValue, setQRValue] = useState("");
  const [IsbnNum, setIsbnNum] = useState("");
  const [barValue, setBarValue] = useState("");
  const [scanType, setScanType] = useState("");

  const callSyncApi = () => {
    if (IsbnNum !== "") {
      DataGetAction(
        "syncBookDetailByIsbn",
        setConfig,
        IsbnNum,
        "outSideData",
        "syncSpinner",
        "syncSuccess",
        "syncError"
      );
    }
  };
  const setFieldsData = () => {
    if (config && config.syncSuccess) {
      let obj = null;
      if (config && config.outSideData) {
        var string = JSON.stringify(config.outSideData.authors);
        var result = string.slice(1, -1);
        obj = {
          bookName: config.outSideData.title,
          authorName: result,
          isbnNumber: IsbnNum,
          publisher: config.outSideData.publisher,
          publishedYear: moment(new Date(config.outSideData.date_published)),
          edition: config.outSideData.edition,
          imageUrl: config.outSideData.image,
          isbnDataObject: config.outSideData,
        };
      } else {
        form.resetFields();
        form.setFieldValue("isbnDataObject", null);
      }
      form.setFieldsValue(obj);
      setConfig("syncSuccess", false);
    }
    if (config && config.syncError) {
      form.setFieldValue("isbnDataObject", null);
      form.resetFields();
      setConfig("syncError", false);
    }
  };
  useEffect(setFieldsData, [
    (config && config.syncSuccess) || (config && config.syncError),
  ]);

  useEffect(() => {
    if (config && config.AddBookModal) {
      setQRValue("");
      setScanType("");
      setScanType("");
    }
  }, [config && config.AddBookModal]);

  return (
    <AddFormModal
      apiName="addBook"
      recordName="bookStoreList"
      modalTitle="Add Book"
      Form={Form}
      form={form}
      width="600px"
      modalName={"AddBookModal"}
    >
      <div className="d-flex gap-2">
        <Form.Item className="d-none" name="imageUrl"></Form.Item>
        <Form.Item className="d-none" name="isbnDataObject"></Form.Item>
        <Form.Item name="seacrchIsbn" className="w-100">
          <InputNumber
            placeholder="Enter ISBN number"
            onChange={(e) => setIsbnNum(e)}
            className="w-100"
          />
        </Form.Item>
        <Button
          loading={config && config.syncSpinner}
          disabled={(config && config.syncSpinner) || IsbnNum === ""}
          size="sm"
          type="primary"
          onClick={() => callSyncApi()}
        >
          Sync
        </Button>
      </div>
      <BookStoreForm
        fotm={form}
        QRValue={QRValue}
        setQRValue={setQRValue}
        setScanType={setScanType}
        scanType={scanType}
        setBarValue={setBarValue}
        barValue={barValue}
      />
    </AddFormModal>
  );
};

export default AddBookStore;
