import { Modal } from "antd";
import React from "react";
import QRCode from "qrcode.react";
import guid from "guid";
const GenerateQrCode = ({
  setQrCodeModel,
  qrCodeModel,
  setQRValue,
  QRValue,
}) => {
  const handleOk = () => {
    const qrCodeGuid = guid.create().value.replace(/-/g, "");
    setQRValue(qrCodeGuid);
    if (qrCodeGuid) {
      setQrCodeModel(false);
    }
  };
  const handleCancel = () => {
    setQrCodeModel(false);
  };

  return (
    <Modal
      closable={false}
      centered
      open={qrCodeModel}
      onOk={handleOk}
      okText="Generate"
      width="300px"
      onCancel={handleCancel}
    >
      <div className="d-flex align-items-center justify-content-center py-4">
        <QRCode
          value={QRValue}
          renderAs="canvas"
          style={{ height: "150px", width: "150px" }}
        />
      </div>
    </Modal>
  );
};

export default GenerateQrCode;
