import { Button, Modal } from "antd";
import React, { useEffect, useState } from "react";
import BarcodeScannerComponent from "react-webcam-barcode-scanner";

const GenerateQrCode = ({
  setBarCodeModel,
  barCodeModel,
  setBarValue,
  barValue,
}) => {
  const [show, setShow] = useState(false);
  const handleOk = () => {
    setBarCodeModel(false);
  };
  const handleCancel = () => {
    setBarCodeModel(false);
    setShow(false);
  };

  useEffect(() => {
    if (barCodeModel) {
      setShow(true);
    }
  }, [barCodeModel]);

  useEffect(() => {
    if (barValue !== "" || barValue !== "Not Found") {
      setBarCodeModel(false);
    }
  }, [barValue]);
  return (
    <>
      {barCodeModel && (
        <Modal
          centered
          open={barCodeModel}
          onOk={handleOk}
          width="300px"
          onCancel={handleCancel}
          footer={null}
          maskClosable={false}
        >
          {barCodeModel && (
            <div className="d-flex align-items-center justify-content-center py-4">
              {show && (
                <BarcodeScannerComponent
                  width={250}
                  height={250}
                  onUpdate={(err, result) => {
                    if (err) {
                      setBarValue("");
                    } else {
                      if (barValue === "") {
                        if (result && result !== undefined) {
                          setBarValue(result.text);
                        } else if (result !== undefined) {
                          setBarValue("Not Found");
                        }
                      }
                    }
                  }}
                />
              )}
            </div>
          )}
          <p className="text-center">{barValue !== "" ? barValue : ""}</p>
          <div className="d-flex justify-content-center">
            <Button
              type="primary"
              className="bold-font"
              style={{ width: "150px" }}
              onClick={() => setBarValue("")}
            >
              Reset
            </Button>
          </div>
        </Modal>
      )}
    </>
  );
};

export default GenerateQrCode;
