import axios from "axios";
import {
  showErrorNotification,
  showSuccessNotification,
} from "../utils/commonFunctions";
import { message } from "antd";
const ROUTE_URL = process.env.REACT_APP_ROOT_URL;
export const DataRequestAction = (
  method,
  apiName,
  setConfig,
  values,
  recordName,
  spinnerName,
  successCase,
  errorCase
) => {
  setConfig(spinnerName, true);
  let Authorization = null;
  let user = JSON.parse(localStorage.getItem("userInfo"));
  if (user && user.token) {
    Authorization = user.token;
  }
  let headers = {
    "Content-Type": "application/json",
    Authorization: Authorization,
    APIType: "web",
  };
  axios({
    url: `${ROUTE_URL}/${apiName}`,
    method: method,
    headers: headers,
    data: values,
  })
    .then(function (response) {
      if (response && response.data && response.data.user) {
        if (recordName) {
          setConfig(successCase, true);
          setConfig(recordName, response.data.user);
          setConfig(spinnerName, false);
          showSuccessNotification(response);
        }
      } else if (response && response.data && response.data.Record) {
        if (recordName) {
          setConfig(recordName, response.data.Record);
        }
        setConfig(successCase, true);
        showSuccessNotification(response);
        setConfig(spinnerName, false);
      } else if (response.data.status === "SUCCESS") {
        setConfig(successCase, true);
        showSuccessNotification(response);
        setConfig(spinnerName, false);
      } else if (response.data.status === "ERROR") {
        setConfig(spinnerName, false);
        setConfig(errorCase, true);
      }
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        setConfig("sessionExp", true);
        setConfig(spinnerName, false);
        setConfig(errorCase, false);
      } else {
        setConfig(spinnerName, false);
        setConfig(errorCase, true);
        showErrorNotification(error);
      }
    });
};
export const DataGetAction = (
  apiName,
  setConfig,
  values,
  recordName,
  spinnerName,
  successCase,
  errorCase
) => {
  setConfig(spinnerName, true);
  let param = "";
  if (values) {
    param = `/${JSON.stringify(values)}`;
  }

  let Authorization = null;
  let user = JSON.parse(localStorage.getItem("userInfo"));
  if (user && user.token) {
    Authorization = user.token;
  }
  let headers = {
    "Content-Type": "application/json",
    Authorization: Authorization,
    APIType: "web",
  };

  axios({
    url: `${ROUTE_URL}/${apiName}${param}`,
    method: "get",
    headers: headers,
  })
    .then(function (response) {
      if (response && response.data && response.data.Record) {
        if (recordName) {
          setConfig(recordName, response.data.Record);
        }
        if (successCase) {
          setConfig(successCase, true);
        }
        setConfig(spinnerName, false);
      } else if (response.data.status === "SUCCESS") {
        setConfig(successCase, true);
        setConfig(spinnerName, false);
      } else if (response.data.status === "ERROR") {
        setConfig(spinnerName, false);
        setConfig(errorCase, true);
        message.error(response.data.message);
      }
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        setConfig("sessionExp", true);
        setConfig(spinnerName, false);
      } else {
        setConfig(spinnerName, false);
        if (errorCase) {
          setConfig(errorCase, true);
        }
        showErrorNotification(error);
      }
    });
};
