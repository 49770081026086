import React, { useEffect } from "react";
import SmallCardGraph from "../../components/Graphs/SmallCardGraph";
import { Col, Row } from "antd";
import Notification from "../../components/Notification";
import FillAreaChart from "../../components/Graphs/FillAreaChart";
import LineAreaChart from "../../components/Graphs/LineAreaChart";
import { DataGetAction } from "../../context/CommonHttp";
import { useApi } from "../../Context";
const Index = () => {
  const { config, setConfig } = useApi();
  const callAllDashboardApis = () => {
    DataGetAction("dailySalesAnalysisOfSoldBooks", setConfig, "", "dailySale");
    DataGetAction("dashboardCardsForUserPortal", setConfig, "", "cardData");
    DataGetAction(
      "getNotificationForUser",
      setConfig,
      { query: "all" },
      "notificationList"
    );
    DataGetAction("monthlySalesAnalytics", setConfig, "", "monthlySales");
  };

  useEffect(callAllDashboardApis, []);
  return (
    <>
      <Row className="gap-md-0 gap-3">
        <Col xl={6} lg={6} md={6} sm={24} xs={24} className="pe-md-3">
          <SmallCardGraph
            count={
              (config && config.cardData && config.cardData.totalBooks) || 0
            }
            desc="Total Books"
            stroke="#C39FDF"
            fill="#F3ECF9"
          />
        </Col>
        <Col xl={6} lg={6} md={6} sm={24} xs={24} className="pe-md-3" l>
          <SmallCardGraph
            count={(config && config.cardData && config.cardData.newBooks) || 0}
            desc="Total New Books"
            stroke="#B8E886"
            fill="#E4F9D7"
          />
        </Col>
        <Col xl={6} lg={6} md={6} sm={24} xs={24} className="pe-md-3">
          <SmallCardGraph
            count={(config && config.cardData && config.cardData.oldBooks) || 0}
            desc="Total Old Books"
            stroke="#F198CA"
            fill="#FCEAF4"
          />
        </Col>
        <Col xl={6} lg={6} md={6} sm={24} xs={24}>
          <SmallCardGraph
            count={
              (config && config.cardData && config.cardData.TotalSold) || 0
            }
            desc="Total Solds"
            stroke="#F198CA"
            fill="#FCEAF4"
          />
        </Col>
      </Row>
      <Row>
        <Col xl={16} lg={16} md={24} sm={24} xs={24} className="pe-lg-3">
          <FillAreaChart data={config && config.monthlySales} />
        </Col>
        <Col xl={8} lg={8} md={24} sm={24} xs={24} className="mt-3">
          <Notification data={config && config.notificationList} />
        </Col>
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          <LineAreaChart data={config && config.dailySale} />
        </Col>
      </Row>
    </>
  );
};

export default Index;
