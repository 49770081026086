import React, { useState } from "react";
import Widget from "../../../components/widget";
import Title from "../../../components/Title";
import SearchResult from "./SearchResult";
import BuyBookList from "./BuyBookList";
import BookFilter from "./BookFilter";

const AddSaleMenu = () => {
  const [select, setSelect] = useState([]);

  return (
    <Widget Header={<Title text="Add Sale" />}>
      <BookFilter />
      <SearchResult setSelect={setSelect} select={select} />
      <BuyBookList setSelect={setSelect} select={select} />
    </Widget>
  );
};

export default AddSaleMenu;
