import { Button, Table, Tooltip } from "antd";
import React from "react";
import { useApi } from "../../../Context";
import moment from "moment";

const SearchResult = ({ setSelect, select }) => {
  const { config } = useApi();
  const setDataInState = (record) => {
    delete record.authorName;
    delete record.publisher;
    delete record.isbnNumber;
    delete record.publishedYear;
    delete record.edition;
    delete record.qrCode;
    delete record.shelfNumber;
    delete record.rackNumber;
    delete record.createdAt;
    delete record.createdBy;
    delete record.categoryName;
    delete record.categoryId;

    const existingIndex = select.findIndex((d) => d._id === record._id);
    if (existingIndex === -1) {
      record["addedQuantity"] = 1;
      record["soldBookId"] = record._id;
      setSelect([...select, record]);
    } else {
      const newDataArray = [...select];
      let updateData = select[existingIndex];
      if (record.quantity > updateData["addedQuantity"]) {
        updateData["addedQuantity"] =
          parseFloat(updateData["addedQuantity"]) + 1;
        record["soldBookId"] = record._id;
      } else {
        record["soldBookId"] = record._id;
      }
      newDataArray[existingIndex] = updateData;
      setSelect(newDataArray);
    }
  };

  const columns = [
    {
      title: "Id",
      render: (record) => <span>{record.bookId}</span>,
      key: "bookId",
    },
    {
      title: "Book Name",
      render: (record) => (
        <span className="text-capitalize">
          {record.bookName && record.bookName.length > 18 ? (
            <Tooltip placement="right" title={record.bookName}>
              {record.bookName &&
                record.bookName.length > 18 &&
                record.bookName.slice(0, 18) + "..."}
            </Tooltip>
          ) : (
            record.bookName || "---"
          )}
        </span>
      ),
      key: "bookName",
    },
    {
      title: "Author Name",
      render: (record) => (
        <span>
          {record.authorName && record.authorName.length > 18 ? (
            <Tooltip placement="right" title={record.authorName}>
              {record.authorName &&
                record.authorName.length > 18 &&
                record.authorName.slice(0, 18) + "..."}
            </Tooltip>
          ) : (
            record.authorName || "---"
          )}
        </span>
      ),
      key: "authorName",
    },
    {
      title: "ISBN Number",
      render: (record) => <span>{record.isbnNumber || "---"}</span>,
      key: "isbnNumber",
    },
    {
      title: "Publisher",
      render: (record) => (
        <span className="text-capitalize">
          {record.publisher && record.publisher.length > 18 ? (
            <Tooltip placement="right" title={record.publisher}>
              {record.publisher &&
                record.publisher.length > 18 &&
                record.publisher.slice(0, 18) + "..."}
            </Tooltip>
          ) : (
            record.publisher || "---"
          )}
        </span>
      ),
      key: "publisher",
    },
    {
      title: "Publish Year",
      render: (record) => (
        <span className="text-capitalize">
          {moment(record.publishedYear).format("YYYY") || "---"}
        </span>
      ),
      key: "publishedYear",
    },
    {
      title: "Edition",
      render: (record) => (
        <span>
          {record.edition && record.edition.length > 18 ? (
            <Tooltip placement="right" title={record.edition}>
              {record.edition &&
                record.edition.length > 18 &&
                record.edition.slice(0, 18) + "..."}
            </Tooltip>
          ) : (
            record.edition || "---"
          )}
        </span>
      ),
      key: "edition",
    },
    {
      title: "Quantity",
      render: (record) => <span>{record.quantity || "---"}</span>,
      key: "quantity",
    },
    {
      title: "Price",
      render: (record) => <span>{record.price || "---"}</span>,
      key: "price",
    },
    {
      title: "Category",
      render: (record) => <span>{record.categoryName || "---"}</span>,
      key: "categoryName",
    },
    {
      title: "Sheif No",
      render: (record) => (
        <span className="text-capitalize">{record.shelfNumber || "---"}</span>
      ),
      key: "shelfNumber",
    },
    {
      title: "Rack No",
      render: (record) => <span>{record.rackNumber || "---"}</span>,
      key: "rackNumber",
    },
    {
      title: "",
      render: (record) => (
        <Button onClick={() => setDataInState(record)} type="primary">
          Select
        </Button>
      ),
    },
  ];

  return (
    <div
      className="my-3"
      style={{
        border: "1px solid #707070",
        borderRadius: "10px",
        overflow: "hidden",
      }}
    >
      <Table
        pagination={false}
        className="table-responsive"
        columns={columns}
        dataSource={config && config["searchBook"]}
      />
    </div>
  );
};

export default SearchResult;
