import React from "react";
import Table from "../../components/Table";
import Widget from "../../components/widget";
import TableHeader from "./components/TableHeader";
// import Action from "../../components/Action";
// import DeleteModal from "../../components/Modal/DeleteModal";
import moment from "moment/moment";
import ViewInvoice from "./components/ViewInvoice";
const Index = () => {
  // const renderActions = (record) => {
  //   return (
  //     <div className="d-flex flex-column ">
  //       <DeleteModal
  //         buttonName="Delete"
  //         title="Are you sure you want to delete this record?"
  //         selectedItem={record}
  //         apiName="deleteCategory"
  //         recordName="OrderList"
  //         oKText="Delete"
  //         cancelText="Cancel"
  //       />
  //     </div>
  //   );
  // };
  const columns = [
    {
      title: "Order Id",
      render: (record) => <span>{record.orderId}</span>,
      key: "orderId",
      width: "150px",
    },
    {
      title: "Amount",
      render: (record) => <span>${record.totalAmount}</span>,
      key: "amount",
      width: "150px",
    },
    {
      title: "Details",
      render: (record) => (
        <ViewInvoice record={record.product} grandTotal={record.totalAmount} />
      ),
      key: "details",
      width: "150px",
    },
    {
      title: "Date",
      render: (record) => (
        <span>{moment(record.createdAt).format("DD-MM-YYYY")}</span>
      ),
      key: "createdAt",
    },
    // {
    //   title: "",
    //   render: (record) => <Action>{renderActions(record)}</Action>,
    //   key: "action",
    //   width: "50px",
    // },
  ];
  return (
    <div>
      <Widget Header={<TableHeader />}>
        <Table
          columns={columns}
          apiName="getOrderList"
          recordName="OrderList"
        />
      </Widget>
    </div>
  );
};

export default Index;
