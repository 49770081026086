import React from "react";
import PaymentCard from "../../../../components/PaymentCard";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
const PaymentScreen = ({ TotalCost }) => {
  const key = process.env.REACT_APP_STRIP_KEY;
  const stripePromise = loadStripe(key);
  return (
    <div className="auth-background">
      <div className="d-flex flex-column gap-5 align-items-center justify-content-center auth-bubble-background">
        <div className="payment-back-card">
          <Elements stripe={stripePromise}>
            <PaymentCard TotalCost={TotalCost} />
          </Elements>
        </div>
      </div>
    </div>
  );
};

export default PaymentScreen;
