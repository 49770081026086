import React from "react";
import AuthLayout from "../../../components/AuthLayout";
import LoginForm from "./components/LoginForm";
import { useApi } from "../../../Context";
import { useNavigate } from "react-router-dom";
import { onLoginSuccess } from "../../../utils/commonFunctions";
import { Divider } from "antd";

const Index = () => {
  const navigation = useNavigate();
  const { setConfig, config } = useApi();
  if (config && config.loginSuccess) {
    onLoginSuccess(setConfig, navigation, config.loginRecord, "user");
    setConfig("loginSuccess", false);
  }
  return (
    <AuthLayout
      title="Account Login"
      content="Welcome, Login to your account to continue"
    >
      <LoginForm />
      <Divider style={{ border: "1px", color: "#ABABAB" }}>
        <span className="font-12 ">OR</span>
      </Divider>
      <div className="d-flex gap-1 mb-2 justify-content-center flex-wrap w-100">
        <span className="font-14 mb-0 regular-font color-9C9EA5">
          Don't have an account?{" "}
        </span>
        <span
          onClick={() => navigation("/signup")}
          className="bold-font font-14 primary-color pointer"
        >
          Register Now
        </span>
      </div>
    </AuthLayout>
  );
};

export default Index;
