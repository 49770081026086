import React, { useState } from "react";
import { Alert, message, Spin, Upload } from "antd";
const { Dragger } = Upload;
const DraggerUploader = ({ disabled }) => {
  const [uploading, setUploading] = useState(false);
  const props = {
    name: "file",
    multiple: false,
    action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
    onChange(info) {
      setUploading(true);
      const { status } = info.file;
      if (status !== "uploading") {
        console.log('');
      }
      if (status === "done") {
        setUploading(false);
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        setUploading(false);
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };
  return (
    <Dragger disabled={uploading || disabled} showUploadList={false} {...props}>
      {!uploading && (
        <p className="mb-0 bold-font color-515151 font-16">
          Click or drag file to this
          <br /> area to upload
        </p>
      )}
      {uploading && (
        <Spin tip="Loading...">
          <Alert
            style={{ border: "none", background: "transparent" }}
            description="Please wait for uploading file."
          />
        </Spin>
      )}
    </Dragger>
  );
};

export default DraggerUploader;
