import React from "react";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

const LineAreaChart = ({ data }) => (
  <div
    className="mt-3 p-3"
    style={{
      background: "#fff",
      boxShadow: "0px 0px 16px #5C5C5C1F",
      borderRadius: "8px",
    }}
  >
    {" "}
    <div className="pb-3">
      <h4 className="regular-font font-22">DAILY ANALYSIS </h4>
    </div>
    <ResponsiveContainer width="100%" height={250}>
      <LineChart
        data={data}
        syncId="anyId"
        margin={{ top: 10, right: 0, left: -15, bottom: 0 }}
      >
        <XAxis dataKey="name" />
        <YAxis />
        <CartesianGrid strokeDasharray="3 3" />
        <Tooltip />
        <Legend />
        <Line
          type="monotone"
          dataKey={"New Books Sold"}
          stroke="#003366"
          activeDot={{ r: 8 }}
        />
        <Line type="monotone" dataKey={"Old Books Sold"} stroke="#FE9E15" />
      </LineChart>
    </ResponsiveContainer>
  </div>
);

export default LineAreaChart;
