import React, { useState } from "react";
import { Form, Row, Col, Button } from "antd";
import PaymentCardImage from "../../assets/images/paymentCard.png";
import BannerImage from "../../assets/images/paymentBanner.png";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { useApi } from "../../Context";
import { DataRequestAction } from "../../context/CommonHttp";

const PaymentCard = ({ TotalCost }) => {
  const { config, setConfig } = useApi();
  const strip = useStripe();
  const elements = useElements();
  const [stripeFormData, setStripeFormData] = useState(null);

  const onFinish = () => {
    const cardNumber = elements.getElement(CardNumberElement);
    strip.createToken(cardNumber).then(function (result) {
      if (result.error) {
        return;
      }
      let data = null;
      if (config && config.signUpData) {
        data = {
          ...config.signUpData,
          brand: result.token.card.brand,
          last4: result.token.card.last4,
          token: result.token.id,
          packageId: config.selectedPackege && config.selectedPackege._id,
        };
      }
      DataRequestAction(
        "post",
        "signUp",
        setConfig,
        data,
        "signUpRecord",
        "paySpin",
        "signupUserSuccess",
        "signupUserError"
      );
    });
  };

  const renderSwpppDocumentFee = () => {
    return (
      <div
        className="px-3 py-4 mb-3 mt-3 d-flex align-items-center justify-content-between flex-wrap"
        style={{ borderRadius: "8px", backgroundColor: "#EDF2F9" }}
      >
        <div className="d-flex justify-content-between align-items-center">
          <span>Total amount</span>
        </div>
        <div className="d-flex justify-content-between">
          <span>
            <i class="fas fa-parking-circle-slash"></i>
          </span>
          <span>${TotalCost || 0}</span>
        </div>
      </div>
    );
  };

  return (
    <Form onFinish={onFinish} name="paymentCard" layout="vertical">
      <div style={{ position: "relative" }}>
        <img className="payment-banner-img" src={BannerImage} alt="" />

        <Row
          className="w-100 h-100"
          style={{ position: "absolute", top: "0px" }}
        >
          <Col xl={4} lg={4} md={4} sm={24} xs={24}></Col>
          <Col xl={16} lg={16} md={16} sm={24} xs={24}>
            <div className="d-flex flex-column align-items-center justify-content-center h-100 w-100">
              <span className="text-white extra-bold-font font-18">
                Order Summary
              </span>
              <span className="text-white extra-bold-font font-16 text-capitalize">
                {`${
                  config &&
                  config.selectedPackege &&
                  config.selectedPackege.packageName
                } / ${
                  config &&
                  config.selectedPackege &&
                  config.selectedPackege.packageDuration
                }`}
              </span>
            </div>
          </Col>
          <Col xl={4} lg={4} md={4} sm={24} xs={24}>
            <div className="d-flex flex-column align-items-center justify-content-center h-100 w-100">
              <span className="text-white extra-bold-font font-20">
                $
                {config &&
                  config.selectedPackege &&
                  config.selectedPackege.packagePrice}
              </span>
            </div>
          </Col>
        </Row>
      </div>
      {renderSwpppDocumentFee()}
      <Row className="mt-4">
        <Col xl={12} lg={12} md={12} sm={12} xs={24}>
          <div
            style={{ borderRadius: "10px", overflow: "hidden" }}
            className="w-100 h-100"
          >
            <img
              src={PaymentCardImage}
              alt=""
              width={"100%"}
              height={"200px"}
            />
          </div>
        </Col>
        <Col xl={12} lg={12} md={12} sm={12} xs={24} className="ps-3 mt-3">
          <div className="cardNumber ">
            <CardNumberElement
              options={{
                style: {
                  base: {
                    border: "1px solid red !important",
                    "::placeholder": {
                      color: "#aab7c4",
                    },
                  },
                  invalid: {
                    color: "#9e2146",
                  },
                },
              }}
              onChange={(value) => {
                setStripeFormData({
                  ...stripeFormData,
                  cardNumber: value.complete,
                });
              }}
            />
          </div>
          <Row className="mx-0 mt-3 mb-4">
            <Col xl={12} lg={12} md={12} sm={12} xs={12} className="pe-2">
              <div className="cardNumber">
                <CardExpiryElement
                  options={{
                    style: {
                      base: {
                        fontSize: "16px",
                        color: "#424770",
                        "::placeholder": {
                          color: "#aab7c4",
                        },
                      },
                      invalid: {
                        color: "#9e2146",
                      },
                    },
                  }}
                  onChange={(value) => {
                    setStripeFormData({
                      ...stripeFormData,
                      expiry: value.complete,
                    });
                  }}
                />
              </div>
            </Col>
            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
              <div className="cardNumber">
                <CardCvcElement
                  options={{
                    style: {
                      base: {
                        fontSize: "16px",
                        color: "#424770",
                        "::placeholder": {
                          color: "#aab7c4",
                        },
                      },
                      invalid: {
                        color: "#9e2146",
                      },
                    },
                  }}
                  onChange={(value) => {
                    setStripeFormData({
                      ...stripeFormData,
                      cvc: value.complete,
                    });
                  }}
                />
              </div>
            </Col>
          </Row>
          <div className="d-flex align-items-center justify-content-center">
            <Button
              loading={config && config.paySpin}
              disabled={config && config.paySpin}
              htmlType="submit"
              size="large"
              className="w-100 payment-button"
            >
              Pay
            </Button>
          </div>
        </Col>
      </Row>
    </Form>
  );
};

export default PaymentCard;
