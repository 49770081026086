import React from "react";
import { Form, Input, Button } from "antd";
import { useApi } from "../../../../Context";
import { useNavigate } from "react-router";
import { DataRequestAction } from "../../../../context/CommonHttp";
const ForgotPasswordForm = () => {
  const navigate = useNavigate();
  const { config, setConfig } = useApi();
  const onFinish = (values) => {
    DataRequestAction(
      "post",
      "forgetPassword",
      setConfig,
      values,
      "",
      "forgotSpin",
      "forgotSuccess",
      ""
    );
  };
  if (config && config.forgotSuccess) {
    navigate("/login");
    setConfig("forgotSuccess", false);
  }

  return (
    <Form layout="vertical" className="auth-form" onFinish={onFinish}>
      <Form.Item
        label="Email"
        name="email"
        rules={[
          { required: true, message: "Email is required!" },
          { type: "email", message: "Enter valid email!" },
        ]}
      >
        <Input placeholder="Enter your email" />
      </Form.Item>
      <Button
        loading={config && config.forgotSpin}
        disabled={config && config.forgotSpin}
        htmlType="submit"
        type="primary"
        className="w-100 extra-bold-font h-40px"
      >
        Send
      </Button>
    </Form>
  );
};

export default ForgotPasswordForm;
