import React, { useState } from "react";
import AuthLayout from "../../../components/AuthLayout";
import SignupForm from "./components/SignupForm";
import { useNavigate } from "react-router-dom";
import { Divider } from "antd";
import PackageScreen from "./components/PackageScreen";
import PaymentScreen from "./components/PaymentScreen";
import { useApi } from "../../../Context";
import { onLoginSuccess } from "../../../utils/commonFunctions";

const Index = () => {
  const navigation = useNavigate();
  const [index, setIndex] = useState(0);
  const { config, setConfig } = useApi();
  if (config && config.signupUserSuccess) {
    onLoginSuccess(setConfig, navigation, config.signUpRecord, "user");
    setConfig("signupUserSuccess", false);
  }
  return (
    <>
      {index === 0 && <PackageScreen setIndex={setIndex} />}
      {index === 1 && (
        <AuthLayout
          title="Setup your account"
          content="Create your account now"
        >
          <SignupForm setIndex={setIndex} />
          <Divider
            className="mt-2 mb-1"
            style={{ border: "1px", color: "#ABABAB" }}
          >
            <span className="font-12 ">OR</span>
          </Divider>
          <div className="d-flex gap-1  justify-content-center w-100">
            <span className="font-14 mb-0 regular-font color-9C9EA5">
              Have an account?{" "}
            </span>
            <span
              onClick={() => navigation(-1)}
              className="bold-font font-14 primary-color pointer text-decoration-underline"
            >
              Login
            </span>
          </div>
        </AuthLayout>
      )}
      {index === 2 && (
        <PaymentScreen
          TotalCost={
            config && config.selectedPackege && config.selectedPackege.packagePrice
          }
        />
      )}
    </>
  );
};

export default Index;
