import React, { useEffect, useState } from "react";
import SimpleModal from "../../../components/Modal/SimpleModal";
import { Form } from "antd";
import BookStoreForm from "./BookStoreForm";

const ViewBookStore = ({ record }) => {
  const [QRValue, setQRValue] = useState("");
  const [barValue, setBarValue] = useState("");
  const [scanType, setScanType] = useState("");
  const setInitialData = () => {
    if (record) {
      if (record.qrCode) {
        setQRValue(record.qrCode);
      }
      if (record.barcode) {
        setBarValue(record.barcode);
      }
      setScanType(record.scanType);
    }
  };

  useEffect(setInitialData, [record]);
  return (
    <SimpleModal
      showButton={false}
      buttonName="View"
      modalTitle="View"
      okText="Ok"
      cancelText="Cancel"
    >
      <Form layout="vertical" initialValues={record}>
        <BookStoreForm
          QRValue={QRValue}
          setQRValue={setQRValue}
          setScanType={setScanType}
          scanType={scanType}
          setBarValue={setBarValue}
          barValue={barValue}
          readOly={true}
        />
      </Form>
    </SimpleModal>
  );
};

export default ViewBookStore;
