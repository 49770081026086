import React from "react";
import Title from "../../../components/Title";
import Filter from "../../../components/Filter";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import { useApi } from "../../../Context";

const TableHeader = () => {
  const { setConfig } = useApi();
  const navigate = useNavigate();
  return (
    <div className="d-flex align-items-center justify-content-between flex-wrap">
      <Title text="Sales Menu" />
      <div className="d-flex align-items-center justify-content-between flex-wrap gap-2">
        <Filter
          SearchBox={true}
          SearchBoxWidth="200px"
          searchPlaceholder="ID / Name"
          apiName="getCategory"
          recordName="categoryList"
        />
        <Button
          type="primary"
          onClick={() => {
            navigate("/saleMenu/addSale");
            setConfig("searchBook", []);
          }}
        >
          Add Sale
        </Button>
      </div>
    </div>
  );
};

export default TableHeader;
