import {
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Radio,
  Row,
  Select,
} from "antd";
import React, { useEffect, useState } from "react";
import { useApi } from "../../../Context";
import { DataGetAction } from "../../../context/CommonHttp";
import QRCode from "qrcode.react";
import Barcode from "react-barcode";
import DraggerUploader from "../../../components/Uploaders/DraggerUploader";
import GenerateQrCode from "./GenerateQrCode";
import GenerateBarCode from "./GenerateBarCode";
const BookStoreForm = ({
  readOly = false,
  setQRValue,
  setScanType,
  scanType,
  setBarValue,
  barValue,
  QRValue,
}) => {
  const [qrCodeModel, setQrCodeModel] = useState(false);
  const [barCodeModel, setBarCodeModel] = useState(false);
  const { config, setConfig } = useApi();
  let arr = Array.from({ length: 100 }, (_, i) => i + 1);
  useEffect(() => {
    DataGetAction(
      "getCategoryList",
      setConfig,
      "",
      "categoryList",
      "categorySpin"
    );
  }, []);
  return (
    <div>
      <Row>
        <Col xl={12} lg={12} md={12} xs={24} sm={24} className="pe-md-3">
          <Form.Item
            name="bookName"
            label="Book Name"
            rules={[{ required: true, message: "Book name is required" }]}
          >
            <Input disabled={readOly} placeholder="Enter book name" />
          </Form.Item>
        </Col>
        <Col xl={12} lg={12} md={12} xs={24} sm={24}>
          <Form.Item
            name="authorName"
            label="Author Name"
            rules={[{ required: true, message: "Author name is required" }]}
          >
            <Input disabled={readOly} placeholder="Enter author name" />
          </Form.Item>
        </Col>
        <Col xl={12} lg={12} md={12} xs={24} sm={24} className="pe-md-3">
          <Form.Item
            name="isbnNumber"
            label="ISBN Number"
            rules={[{ required: true, message: "ISBN number is required" }]}
          >
            <InputNumber
              min={0}
              className="w-100"
              disabled={readOly}
              placeholder="Enter ISBN number"
            />
          </Form.Item>
        </Col>
        <Col xl={12} lg={12} md={12} xs={24} sm={24}>
          <Form.Item
            name="publisher"
            label="Publisher"
            rules={[{ required: true, message: "Publisher is required" }]}
          >
            <Input disabled={readOly} placeholder="Enter publisher" />
          </Form.Item>
        </Col>
        <Col xl={12} lg={12} md={12} xs={24} sm={24} className="pe-md-3">
          <Form.Item
            name="publishedYear"
            label="Published Year"
            rules={[{ required: true, message: "Published year is required" }]}
          >
            <DatePicker
              disabled={readOly}
              picker="year"
              disabledDate={(current) =>
                current && current.valueOf() > Date.now()
              }
              className="w-100"
              placeholder="Enter publisher year"
            />
          </Form.Item>
        </Col>
        <Col xl={12} lg={12} md={12} xs={24} sm={24}>
          <Form.Item
            name="edition"
            label="Edition"
            rules={[{ required: true, message: "Edition is required" }]}
          >
            <Input disabled={readOly} placeholder="Enter edition" />
          </Form.Item>
        </Col>
        <Col xl={12} lg={12} md={12} xs={24} sm={24} className="pe-md-3">
          <Form.Item
            name="price"
            label="Price"
            rules={[{ required: true, message: "Price is required" }]}
          >
            <InputNumber
              min={0.1}
              disabled={readOly}
              className="w-100"
              placeholder="Enter price"
            />
          </Form.Item>
        </Col>
        <Col xl={12} lg={12} md={12} xs={24} sm={24}>
          <Form.Item
            name="categoryId"
            label="Category"
            rules={[{ required: true, message: "Category is required" }]}
          >
            <Select
              loading={config && config.categorySpin}
              disabled={readOly}
              placeholder="Enter category"
            >
              {config &&
                config.categoryList &&
                config.categoryList.length > 0 &&
                config.categoryList.map((item) => (
                  <Select.Option value={item._id}>
                    {item.categoryName}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xl={12} lg={12} md={12} xs={24} sm={24} className="pe-md-3">
          <Form.Item
            name="shelfNumber"
            label="Shelf No"
            rules={[{ required: true, message: "Shelf no is required" }]}
          >
            <Select disabled={readOly} placeholder="Enter shelf no">
              {arr &&
                arr.map((item) => (
                  <Select.Option value={`S-${item}`}>S-{item}</Select.Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xl={12} lg={12} md={12} xs={24} sm={24}>
          <Form.Item
            name="rackNumber"
            label="Rack No"
            rules={[{ required: true, message: "Rack no is required" }]}
          >
            <Select disabled={readOly} placeholder="Enter rack no">
              {arr &&
                arr.map((item) => (
                  <Select.Option value={`R-${item}`}>R-{item}</Select.Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xl={12} lg={12} md={12} xs={24} sm={24} className="pe-md-3">
          <Form.Item
            name="quantity"
            label="Quantity"
            rules={[{ required: true, message: "Quantity is required" }]}
          >
            <InputNumber
              min={1}
              disabled={readOly}
              className="w-100"
              placeholder="Enter quantity"
            />
          </Form.Item>
        </Col>
        <Col xl={12} lg={12} md={12} xs={24} sm={24}>
          <Form.Item
            name="condition"
            label="Condition"
            rules={[{ required: true, message: "Condition no is required" }]}
          >
            <Select disabled={readOly} placeholder="Select condition">
              <Select.Option value="new">New</Select.Option>
              <Select.Option value="old">Old</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col xl={24} lg={24} md={24} xs={24} sm={24} className="mb-3">
          <DraggerUploader disabled={readOly} />
        </Col>
        <>
          <Form.Item
            name="scanType"
            rules={[{ required: true, message: "Please select one type" }]}
          >
            <Radio.Group
              disabled={readOly}
              onChange={(e) => {
                setScanType(e.target.value);
                setQRValue("");
                setBarValue("");
              }}
            >
              <Radio
                onClick={() => setQrCodeModel(true)}
                value="generateQrCode"
              >
                Generate Qr code
              </Radio>
              <Radio onClick={() => setBarCodeModel(true)} value="scanBarcode">
                Scan Barcode
              </Radio>
            </Radio.Group>
          </Form.Item>
          <Col
            xl={24}
            lg={24}
            md={24}
            xs={24}
            sm={24}
            className="hide-input-form"
          >
            {scanType === "generateQrCode" && (
              <>
                {QRValue && (
                  <Form.Item name={"qrCode"} initialValue={QRValue}></Form.Item>
                )}
                {!QRValue && (
                  <Form.Item
                    name={"qrCode"}
                    rules={[
                      {
                        required: true,
                        message: "Please generate qrcode",
                      },
                    ]}
                  ></Form.Item>
                )}
              </>
            )}
            {scanType === "scanBarcode" && (
              <>
                {barValue && (
                  <Form.Item
                    name={"barcode"}
                    initialValue={barValue}
                  ></Form.Item>
                )}
                {!barValue && (
                  <Form.Item
                    name={"barcode"}
                    rules={[
                      {
                        required: true,
                        message: "Please scan barcode",
                      },
                    ]}
                  ></Form.Item>
                )}
              </>
            )}
          </Col>
          <Col
            xl={24}
            lg={24}
            md={24}
            xs={24}
            sm={24}
            className="d-flex align-items-center"
          >
            {QRValue !== "" && (
              <div
                className="d-flex px-2  me-2 align-items-center justify-content-center"
                style={{ border: "1px solid", padding: "18px 0" }}
              >
                <QRCode
                  value={QRValue}
                  renderAs="canvas"
                  style={{ height: "70px", width: "100px" }}
                />
              </div>
            )}
            {barValue !== "" && (
              <>
                {barValue !== "Not Found" && (
                  <div className="barcode" style={{ border: "1px solid" }}>
                    <Barcode value={barValue} displayValue={false} />
                  </div>
                )}
              </>
            )}
          </Col>
        </>
      </Row>

      <GenerateQrCode
        setQrCodeModel={setQrCodeModel}
        qrCodeModel={qrCodeModel}
        setQRValue={setQRValue}
        QRValue={QRValue}
      />

      <GenerateBarCode
        setBarCodeModel={setBarCodeModel}
        barCodeModel={barCodeModel}
        setBarValue={setBarValue}
        barValue={barValue}
      />
    </div>
  );
};

export default BookStoreForm;
