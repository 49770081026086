import React, { useState } from "react";
import { Button, Modal, message } from "antd";
import axios from "axios";
import { useApi } from "../../Context";
import { IoMdCloseCircle } from "react-icons/io";
import {
  showErrorNotification,
  showSuccessNotification,
} from "../../utils/commonFunctions";

const ROOT_URL = process.env.REACT_APP_ROOT_URL;
const EditFormModal = ({
  Form,
  form,
  children,
  modalTitle = "Add",
  buttonName = "Add",
  method = "post",
  okText = "Submit",
  cancelText = "Cancel",
  apiName,
  recordName,
  showButton = true,
  width,
  extraValues,
  modalName,
}) => {
  const { config, setConfig } = useApi();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [addConfirmSpin, setAddConfirmSpin] = useState(false);

  const showModal = () => {
    setConfig(modalName, true);
    setIsModalOpen(true);
  };
  const handleOk = async (values) => {
    setAddConfirmSpin(true);
    return await new Promise((resolve, reject) => {
      let Authorization = null;
      let user = JSON.parse(localStorage.getItem("userInfo"));
      if (user && user.token) {
        Authorization = user.token;
      }
      let headers = {
        "Content-Type": "application/json",
        Authorization: Authorization,
        APIType: "web",
      };
      axios({
        url: `${ROOT_URL}/${apiName}`,
        method: method,
        headers: headers,
        data: { ...values, ...extraValues },
      })
        .then(function (response) {
          if (response && response.data && response.data.Record) {
            if (response.data.status === "SUCCESS") {
              let updateObj = {};
              if (
                config &&
                config[recordName] &&
                config[recordName].tableData &&
                config[recordName].tableData.length > 0
              ) {
                updateObj = {
                  ...response.data.Record,
                  tableData: [
                    response.data.Record.tableData[0],
                    ...config[recordName].tableData,
                  ],
                };
              } else {
                updateObj = response.data.Record;
              }
              form.resetFields();
              setConfig(recordName, updateObj);
              setAddConfirmSpin(false);
              setIsModalOpen(false);
              showSuccessNotification(response);
            }
          } else {
            setAddConfirmSpin(false);
            if (response.data.status === "ERROR") {
              message.error(response.data.message);
            }
          }
          resolve();
        })
        .catch(function (error) {
          setAddConfirmSpin(false);
          showErrorNotification(error);
          reject();
        });
    }).catch(() => {
      setAddConfirmSpin(false);
    });
  };
  const handleCancel = () => {
    setConfig(modalName, false);
    setIsModalOpen(false);
    form.resetFields();
  };
  return (
    <>
      {showButton && (
        <Button
          type="primary"
          className="font-16 regular-font py-0"
          onClick={() => showModal()}
        >
          {buttonName}
        </Button>
      )}
      {!showButton && (
        <span
          className="pointer color-515151 font-16 regular-font"
          onClick={() => showModal()}
        >
          {buttonName}
        </span>
      )}
      <Modal
        title={modalTitle}
        className="form-modal"
        open={isModalOpen}
        closeIcon={<IoMdCloseCircle size="20" />}
        okText={okText}
        width={width}
        cancelText={cancelText}
        confirmLoading={addConfirmSpin}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              handleOk(values);
            })
            .catch((info) => {});
        }}
        onCancel={handleCancel}
      >
        <hr />
        <div className="px-3">
          <Form layout="vertical" name="Form" form={form}>
            {children}
          </Form>
        </div>
        <hr />
      </Modal>
    </>
  );
};
export default EditFormModal;
