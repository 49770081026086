import React, { useState } from "react";
import { Button, Modal } from "antd";
import { IoMdCloseCircle } from "react-icons/io";

const SimpleModal = ({
  children,
  modalTitle = "Add",
  buttonName = "Add",
  okText = "Submit",
  cancelText = "Cancel",
  showButton = true,
  width,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <>
      {showButton && (
        <Button
          type="primary"
          className="font-16 regular-font py-0"
          onClick={() => showModal()}
        >
          {buttonName}
        </Button>
      )}
      {!showButton && (
        <span
          className="pointer color-515151 font-16 regular-font"
          onClick={() => showModal()}
        >
          {buttonName}
        </span>
      )}
      <Modal
        title={modalTitle}
        className="form-modal"
        open={isModalOpen}
        closeIcon={<IoMdCloseCircle size="20" />}
        okText={okText}
        width={width}
        cancelText={cancelText}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <hr />
        <div className="px-3">{children}</div>
        <hr />
      </Modal>
    </>
  );
};
export default SimpleModal;
