import React from "react";
import { Route, Routes } from "react-router-dom";
import Dashboard from "../screens/Dashboard";
import BookStore from "../screens/BookStore";
import SaleMenu from "../screens/SaleMenu";
import Setting from "../screens/Setting";
import ProtectedRoutes from "./ProtectedRoutes";
import AddSaleMenu from "../screens/SaleMenu/components/AddSaleMenu";

const PrivateRoutes = () => {
  return (
    <Routes>
      <Route element={<ProtectedRoutes />}>
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/bookStore" element={<BookStore />} />
        <Route path="/saleMenu" element={<SaleMenu />} />
        <Route path="/saleMenu/addSale" element={<AddSaleMenu />} />
        <Route path="/setting" element={<Setting />} />
        <Route path="/" element={<Dashboard />} />
      </Route>
    </Routes>
  );
};

export default PrivateRoutes;
