import React from "react";
import Layout from "../components/Layout";
import { Navigate, Outlet } from "react-router-dom";
import { useApi } from "../Context";
const ProtectedRoutes = () => {
  const { user } = useApi().config;

  return user ? (
    <Layout>
      <Outlet />
    </Layout>
  ) : (
    <Navigate to="/login" />
  );
};

export default ProtectedRoutes;
