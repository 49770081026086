import React, { useEffect } from "react";
import { useApi } from "../../../../Context";
import { DataGetAction } from "../../../../context/CommonHttp";
import { Button, Spin } from "antd";
import TrialBackground from "../../../../assets/images/trialBackground.png";
import payBackground from "../../../../assets/images/payBackground.png";
import TrialShape from "../../../../assets/images/trialShap.png";
import payShape from "../../../../assets/images/payShap.png";
const PackageScreen = ({ setIndex }) => {
  const { config, setConfig } = useApi();

  useEffect(() => {
    DataGetAction(
      "getPackagesListForUser",
      setConfig,
      "",
      "authPackageList",
      "authPackages",
      "",
      ""
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderCard = () => {
    if (
      config &&
      config.authPackageList &&
      config &&
      config.authPackageList.length > 0
    ) {
      return config.authPackageList.map((item) => {
        return (
          <div
            style={{
              height: "500px",
              width: "300px",
              background: "#fff",
              boxShadow: " 0px 8px 9px #3989F029",
              borderRadius: "10px",
              overflowY: "auto",
            }}
          >
            <div
              className="w-100 d-flex align-items-center justify-content-center"
              style={{
                backgroundSize: "cover",
                backgroundImage: `url(${
                  item.packageDuration === "free"
                    ? TrialBackground
                    : payBackground
                })`,
                height: "150px",
              }}
            >
              <h1 className="text-capitalize bold-font">{item.packageName}</h1>
            </div>
            <div
              className="d-flex align-items-center justify-content-center flex-column"
              style={{
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundImage: `url(${
                  item.packageDuration === "free" ? TrialShape : payShape
                })`,
                height: "120px",
                marginTop: "-20px",
              }}
            >
              <p className="mb-0 font-18 color-white bold-font">
                ${item.packagePrice}
              </p>
              <p className="mb-0 font-18 text-capitalize color-white regular-font">
                /{item.packageDuration}
              </p>
            </div>
            <div
              className="p-3"
              style={{
                height: "180px",
                overflowY: "auto",
              }}
            >
              {item.description && item.description.length > 200
                ? item.description.slice(0, 200) + "..."
                : item.description}
            </div>
            <div className="d-flex align-items-center justify-content-center">
              <Button
                className="px-5"
                size="large"
                onClick={() => {
                  setConfig("selectedPackege", item);
                  setIndex(1);
                }}
              >
                {item.packageDuration === "free" ? "Try Now" : "Buy Now"}
              </Button>
            </div>
          </div>
        );
      });
    }
  };
  return (
    <div className="auth-background">
      <div className="d-flex flex-column gap-5 align-items-center justify-content-center auth-bubble-background">
        <div
          className="d-flex align-items-center flex-column justify-content-center gap-1"
          style={{
            width: "300px",
            height: "100px",
            boxShadow: "0px 2px 9px #3989f029",
            background: "#fff",
            borderRadius: "10px",
          }}
        >
          <span className="text-center regular-font font-14">
            Book Store Pricing
          </span>
          <span className="text-center bold-font font-16 px-3">
            Trial plan with all the basic features Pro plan for advanced users
          </span>
        </div>
        <div className="d-flex flex-wrap gap-4 align-items-center justify-content-center">
          {config && config.authPackages && <Spin size="large" />}
          {config && !config.authPackages && renderCard()}
        </div>
      </div>
    </div>
  );
};

export default PackageScreen;
