import { Button, Col, Form, Input, Row, Select } from "antd";
import React, { useEffect } from "react";
import { DataGetAction } from "../../../context/CommonHttp";
import { useApi } from "../../../Context";

const BookFilter = () => {
  const { config, setConfig } = useApi();
  const onFinish = (values) => {
    DataGetAction("searchBook", setConfig, values, "searchBook", "searching");
  };
  useEffect(() => {
    DataGetAction(
      "getCategoryList",
      setConfig,
      "",
      "categoryList",
      "categorySpin"
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div
      style={{ border: "1px solid #707070", borderRadius: "10px" }}
      className="p-3"
    >
      <Form layout="vertical" onFinish={onFinish}>
        <Row className="align-items-center">
          <Col xl={4} lg={4} md={8} sm={12} xs={24} className="pe-md-2">
            <Form.Item name="bookName" label="Book Name">
              <Input placeholder="Enter book name" />
            </Form.Item>
          </Col>
          <Col xl={4} lg={4} md={8} sm={12} xs={24} className="pe-md-2">
            <Form.Item name="authorName" label="Author Name">
              <Input placeholder="Enter author name" />
            </Form.Item>
          </Col>
          <Col xl={4} lg={4} md={8} sm={12} xs={24} className="pe-md-2">
            <Form.Item name="edition" label="Edition">
              <Input placeholder="Enter edition" />
            </Form.Item>
          </Col>
          <Col xl={4} lg={4} md={8} sm={12} xs={24} className="pe-md-2">
            <Form.Item name="categoryId" label="Category">
              <Select
              allowClear
                loading={config && config.categorySpin}
                placeholder="Enter category"
              >
                {config &&
                  config.categoryList &&
                  config.categoryList.length > 0 &&
                  config.categoryList.map((item) => (
                    <Select.Option value={item._id}>
                      {item.categoryName}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xl={4} lg={4} md={8} sm={12} xs={24} className="pe-md-2">
            <Form.Item name="condition" label="Condition">
              <Select allowClear placeholder="Enter condition">
                <Select.Option value="new">New</Select.Option>
                <Select.Option value="old">Old</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xl={4} lg={4} md={8} sm={12} xs={24}>
            <Button
              loading={config && config.searching}
              disabled={config && config.searching}
              className="w-100 mt-3"
              type="primary"
              htmlType="submit"
            >
              Search
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default BookFilter;
