import React from "react";
import Title from "../../../components/Title";
import Filter from "../../../components/Filter";
import AddBookStore from "./AddBookStore";

const TableHeader = () => {
  return (
    <div className="d-flex align-items-center justify-content-between flex-wrap">
      <Title text="Book Store" />
      <div className="d-flex align-items-center gap-2 flex-wrap">
        <Filter
          SearchBox={true}
          SearchBoxWidth="200px"
          searchPlaceholder="ID / Name / Auth / ISBN"
          apiName="getBookList"
          recordName="bookStoreList"
        />
        <AddBookStore />
      </div>
    </div>
  );
};

export default TableHeader;
