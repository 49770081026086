import { Button, Modal } from "antd";
import React, { useRef, useState } from "react";
import QRCode from "qrcode.react";
import ReactToPrint from "react-to-print";
const ViewQrCode = ({ value }) => {
  const [viewQrModel, setViewQrModel] = useState(false);
  const componentRef = useRef();

  const handleCancel = () => {
    setViewQrModel(false);
  };
  return (
    <>
      <span
        className="primary-color pointer"
        onClick={() => setViewQrModel(true)}
      >
        View
      </span>
      <Modal
        closable={false}
        footer={null}
        centered
        open={viewQrModel}
        width="300px"
      >
        <div
          ref={componentRef}
          className="d-flex align-items-center justify-content-center py-4"
        >
          <QRCode
            value={value}
            renderAs="canvas"
            style={{ height: "150px", width: "150px" }}
          />
        </div>
        <div className="d-flex justify-content-end gap-2">
          <Button onClick={() => handleCancel()}>Close</Button>
          <ReactToPrint
            trigger={() => <Button type="primary">Print</Button>}
            content={() => componentRef.current}
          />
        </div>
      </Modal>
    </>
  );
};

export default ViewQrCode;
