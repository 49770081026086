import React, { useEffect, useState } from "react";
import EditFormModal from "../../../components/Modal/EditFormModal";
import { Form } from "antd";
import BookStoreForm from "./BookStoreForm";

const EditUser = ({ record }) => {
  const [form] = Form.useForm();
  const [QRValue, setQRValue] = useState("");
  const [barValue, setBarValue] = useState("");
  const [scanType, setScanType] = useState("");
  const setInitialData = () => {
    if (record) {
      if (record.qrCode) {
        setQRValue(record.qrCode);
      }
      if (record.barcode) {
        setBarValue(record.barcode);
      }
      setScanType(record.scanType);
    }
  };

  useEffect(setInitialData, [record]);

  return (
    <EditFormModal
      apiName="updateBook"
      recordName="bookStoreList"
      initialValues={record}
      Form={Form}
      form={form}
    >
      <BookStoreForm
        QRValue={QRValue}
        setQRValue={setQRValue}
        setScanType={setScanType}
        scanType={scanType}
        setBarValue={setBarValue}
        barValue={barValue}
      />
    </EditFormModal>
  );
};

export default EditUser;
