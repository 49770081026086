import { Button, Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import EditableTable from "./EditTable";
import { DataRequestAction } from "../../../context/CommonHttp";
import { useApi } from "../../../Context";
import { useNavigate } from "react-router-dom";

const BuyBookList = ({ select, setSelect }) => {
  const { config, setConfig } = useApi();
  const navigate = useNavigate();
  const priceSum =
    select &&
    select.reduce((accumulator, object) => {
      return accumulator + object.addedQuantity * object.price;
    }, 0);

  const quantitySum =
    select &&
    select.reduce((accumulator, object) => {
      return accumulator + object.addedQuantity;
    }, 0);
  const addOrder = () => {
    DataRequestAction(
      "post",
      "addOrder",
      setConfig,
      { product: select },
      "",
      "addOrderSpinner",
      "orderSuccess"
    );
  };

  useEffect(() => {
    if (config.orderSuccess) {
      navigate("/saleMenu");
      setConfig("orderSuccess", false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config && config.orderSuccess]);
  return (
    <>
      <Row>
        <Col xl={16} lg={16} md={16} sm={24} xs={24} className="pe-md-3">
          <div
            style={{
              border: "1px solid #707070",
              borderRadius: "10px",
              height: "200px",
              overflowX: "auto",
              overflowY: "auto",
            }}
          >
            <EditableTable setTableData={setSelect} tableData={select} />
          </div>
        </Col>
        <Col xl={8} lg={8} md={8} sm={24} xs={24} className="mt-md-0 mt-3">
          <div
            style={{
              border: "1px solid #707070",
              borderRadius: "10px",
              height: "200px",
            }}
          >
            <h1
              className="bold-font p-3 mb-0"
              style={{ color: "#22181C", fontSize: "26px" }}
            >
              Grand Total
            </h1>
            <hr className="mt-0" />
            <div className="p-4">
              <div className="d-flex align-items-center flex-wrap justify-content-between mb-3">
                <span className="font-16 bold-font">Books</span>
                <span className="font-16"> {quantitySum}</span>
              </div>
              <div className="d-flex align-items-center flex-wrap justify-content-between">
                <span className="font-16 bold-font">Total</span>
                <span className="font-16">{priceSum}</span>
              </div>
            </div>
          </div>
        </Col>
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          <div className="py-5 d-flex align-items-center justify-content-center gap-2">
            <Button
              size="large"
              onClick={() => {
                navigate("/saleMenu");
              }}
            >
              Cancel
            </Button>
            <Button
              disabled={
                (select && select.length === 0) ||
                (config && config.addOrderSpinner)
              }
              size="large"
              htmlType="submit"
              type="primary"
              loading={config && config.addOrderSpinner}
              onClick={() => addOrder()}
            >
              Save
            </Button>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default BuyBookList;
