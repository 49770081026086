import React from "react";
import Table from "../../components/Table";
import Widget from "../../components/widget";
import Action from "../../components/Action";
import EditBookStore from "./components/EditBookStore";
import TableHeader from "./components/TableHeader";
import moment from "moment/moment";
import ViewBookStore from "./components/ViewBookStore";
import ViewQrCode from "./components/viewQrCode";
import { Image, Tooltip } from "antd";
const Index = () => {
  const renderActions = (record) => {
    record.publishedYear = moment(record.publishedYear);
    return (
      <div className="d-flex flex-column">
        <ViewBookStore record={record} />
        <EditBookStore record={record} />
      </div>
    );
  };
  const columns = [
    {
      title: "Id",
      render: (record) => <span>{record.bookId}</span>,
      key: "userId",
    },
    
    {
      title: "Book Name",
      render: (record) => (
        <span className="text-capitalize d-flex gap-1">
          {record && record.imageUrl && (
            <Image
              src={record.imageUrl}
              alt=""
              width={"70px"}
              height={"50px"}
            />
          )}
          {record.bookName && record.bookName.length > 18 ? (
            <Tooltip placement="right" title={record.bookName}>
              {record.bookName &&
                record.bookName.length > 18 &&
                record.bookName.slice(0, 18) + "..."}
            </Tooltip>
          ) : (
            record.bookName || "---"
          )}
        </span>
      ),
      key: "bookName",
    },
    {
      title: "Author Name",
      render: (record) => (
        <span className="text-capitalize">
          {record.authorName && record.authorName.length > 18 ? (
            <Tooltip placement="right" title={record.authorName}>
              {record.authorName &&
                record.authorName.length > 18 &&
                record.authorName.slice(0, 18) + "..."}
            </Tooltip>
          ) : (
            record.authorName || "---"
          )}
        </span>
      ),
      key: "authorName",
    },
    {
      title: "ISBN Number",
      render: (record) => <span>{record.isbnNumber || "---"}</span>,
      key: "phoneNumber",
    },
    {
      title: "Publisher",
      render: (record) => (
        <span className="text-capitalize">
          {record.publisher && record.publisher.length > 18 ? (
            <Tooltip placement="right" title={record.publisher}>
              {record.publisher &&
                record.publisher.length > 18 &&
                record.publisher.slice(0, 18) + "..."}
            </Tooltip>
          ) : (
            record.publisher || "---"
          )}
        </span>
      ),
      key: "publisher",
    },
    {
      title: "Publish Year",
      render: (record) => (
        <span className="text-capitalize">
          {moment(record.publishedYear).format("YYYY") || "---"}
        </span>
      ),
      key: "storeName",
    },
    {
      title: "Edition",
      render: (record) => (
        <span className="text-capitalize">
          {record.edition && record.edition.length > 18 ? (
            <Tooltip placement="right" title={record.edition}>
              {record.edition &&
                record.edition.length > 18 &&
                record.edition.slice(0, 18) + "..."}
            </Tooltip>
          ) : (
            record.edition || "---"
          )}
        </span>
      ),
      key: "storeAddress",
    },
    {
      title: "Quantity",
      render: (record) => (
        <span>
          {record.quantity
            ? record.quantity
            : record.quantity === 0
            ? record.quantity
            : "---"}
        </span>
      ),
      key: "storeAddress",
    },
    {
      title: "Price",
      render: (record) => <span>${record.price || "---"}</span>,
      key: "storeAddress",
    },
    {
      title: "Category",
      render: (record) => <span>{record.categoryName || "---"}</span>,
      key: "storeAddress",
    },
    {
      title: "Sheif No",
      render: (record) => (
        <span className="text-capitalize">{record.shelfNumber || "---"}</span>
      ),
      key: "storeAddress",
    },
    {
      title: "Rack No",
      render: (record) => <span>{record.rackNumber || "---"}</span>,
      key: "storeAddress",
    },
    {
      title: "Qr Code",
      render: (record) => (
        <>
          {record && record.qrCode ? (
            <ViewQrCode value={record.qrCode} />
          ) : (
            <span>----</span>
          )}
        </>
      ),
      key: "storeAddress",
    },

    {
      title: "",
      render: (record) => <Action>{renderActions(record)}</Action>,
      key: "action",
      width: "50px",
    },
  ];
  return (
    <div>
      <Widget Header={<TableHeader />}>
        <Table
          columns={columns}
          recordName="bookStoreList"
          apiName="getBookList"
        />
      </Widget>
    </div>
  );
};

export default Index;
