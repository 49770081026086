import { Avatar, Col, Input, Row } from "antd";
import React from "react";
import { BiEdit } from "react-icons/bi";
import PhoneInput from "react-phone-input-2";
import EditSetting from "./components/EditSetting";
import Reset from "./components/ResetPassword";
import { useApi } from "../../Context";
const Index = () => {
  const { config } = useApi();
  const { user } = config;
  return (
    <Row>
      <Col xl={9} lg={10} md={12} xs={24} sm={24} className="setting-card p-3">
        <div className="d-flex justify-content-between mb-3">
          <div className="d-flex align-items-center gap-2">
            <Avatar src={user && user.profileImageUrl} size={80} style={{ boxShadow: "0px 3px 6px #00000029" }} />
            <div>
              <h4 className="mb-0 font-26 regular-font text-capitalize">
                {user && user.fullName}
              </h4>

              <Reset />
            </div>
          </div>
          <span className="pointer">
            <EditSetting
              editButton={<BiEdit size={26} color="#3989f0" />}
              initialValues={user}
            />
          </span>
        </div>
        <span className="regular-font font-14">Full Name</span>
        <Input
          className="my-2 text-capitalize"
          readOnly
          value={(user && user.fullName) || "---"}
        />
        <span className="regular-font font-14 mb-2">Email</span>
        <Input
          className="my-2"
          readOnly
          value={(user && user.email) || "example@gmail.com"}
        />
        <p className="regular-font font-14 mb-2"> Phone Number</p>
        <PhoneInput
          inputStyle={{
            width: "100%",
          }}
          disabled
          value={(user && user.phoneNumber) || "0000000000"}
        />
      </Col>
    </Row>
  );
};

export default Index;
